import { graphql } from 'gatsby';
import { useI18next, Link } from 'gatsby-plugin-react-i18next';

import Layout from '../components/Layout/Layout';

import styles from '../styles/pages/404.module.scss';

import logo from '../assets/logotype.svg';

export const devNotFoundPageQuery = graphql`
  query DevNotFoundPageQuery($language: String!) {
    site {
      siteMetadata {
        name
        tagline
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const DevNotFoundPage = () => {
  const { t, originalPath, i18n } = useI18next();

  return (
    <Layout
      t={t}
      originalPath={originalPath}
      i18n={i18n}
      SEOProps={{ title: 'Not Found' }}
    >
      <div className={styles.notFound}>
        <img src={logo} alt="Smartpay" width={448} />
        <h1>
          <span className={styles.left}>404</span>
          <span className={styles.right}>ページが見つかりませんでした</span>
        </h1>
        <Link to="/">トップページに戻る</Link>
      </div>
    </Layout>
  );
};

export default DevNotFoundPage;
